#react-paginate ul {
    display: flex;
    justify-content: center;
    /* margin-left: 20px; */
    /* padding-left: 0; */
    font-size: 25px;
}

#react-paginate li {
    display: inline-block;
    border: 1px solid rgb(224, 224, 224);
    color: #3846ce;
    cursor: pointer;
    margin-right: 3px;
    border-radius: 5px;
    margin-bottom: 5px;
}

#react-paginate li a {
    padding: 2px 5px;
    display: inline-block;
    outline: none;
} 

#react-paginate li.active {
    background: #3846ce;
    color: rgb(255, 255, 255);
    outline: none;
}