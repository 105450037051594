.textPrimary {
  color: #3846ce !important;
  text-decoration: none;
}

.textSecondary {
  color: #818181 !important;
  text-decoration: none;
}

.App button.MuiButtonBase-root.btnPrimary,
.MuiModal-root .MuiButtonBase-root.btnPrimary {
  background-color: #3846ce;
  border-radius: 8px;
  box-shadow: none;
  font-weight: 500;
  font-size: 16px;
  padding: 9px 28px;
  text-transform: capitalize;
}

button.MuiButtonBase-root.Mui-disabled.btnPrimary {
  background-color: rgba(0, 0, 0, 0.12);
}

.App button.MuiButtonBase-root.btnPrimary:hover {
  color: #fff !important;
  background-color: #3846ce;
}

.MuiModal-root.call-modal .MuiButtonBase-root.btnPrimary {
  padding: 18px 28px;
  font-size: 18px;
  color: #F0F6F9;
}

.mobileMenu .MuiListItemButton-root span {
  font-weight: 700;
}

.mobileMenu .btnClose svg {
  font-size: 18px;
  color: #7691bb;
  cursor: pointer;
  margin: 12px;
}

a.MuiLink-root {
  color: #3846ce;
  font-weight: 300;
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b6b6b6;
  border-radius: 10px;
}